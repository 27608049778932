import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import { Helmet } from 'react-helmet';

// Hero Social Links
const heroSocialLinks = [
  {
    name: 'Instagram',
    links: 'https://instagram.com/karibtech',
  },
  {
    name: 'WhatsApp',
    links: 'https://wa.me/+590690344989',
  },
];

// FunFact Data
const funfaceData = [
  {
    title: 'Projets Complétés',
    factNumber: '120',
  },
  {
    title: 'Clients',
    factNumber: '70',
  },
  {
    title: 'Années d\'expérience',
    factNumber: '9',
  },
  {
    title: 'Heures en moyennne pour créer un site Web',
    factNumber: '60',
  },
];

export default function Home() {
  pageTitle('Accueil');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="Conception de site Web en Guadeloupe - Demandez un devis pour vos projets de site internet. Experts en Wordpress, E-commerce et développement spécifique." />
      </Helmet>
      {/* Start Hero Section */}
      <Hero
        title="Conception de site Web en Guadeloupe"
        subtitle="À la recherche d'un développeur et Web designer pour vos projets de conception de site internet ? Nous sommes là pour répondre à vos besoins."
        btnText="Demandez un devis"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Nos réseaux"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Quelques chiffres"
          subtitle="Expertise confirmée par 120+ projets, 70 clients satisfaits et 9+ années d'expérience."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Nos Prestations Sur Mesure"
                subtitle="Nos services"
                btnText="Voir tout"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Conception de Site Web"
                    link="/service/conception-site-web"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Identité Visuelle"
                    link="/service/identite-visuelle"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Référencement Naturel"
                    link="/service/referencement-naturel"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Maintenance Site Web"
                    link="/service/maintenance-site-web"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Référencement Payant"
                    link="/service/referencement-payant"
                    src="/images/service_6.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
      {/* Start Awards Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Expertises"
                subtitle="À Propos"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Awards Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Obtenez un devis sous 48h"
          btnText="Prendre rendez-vous"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
