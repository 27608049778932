import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://wa.me/+590690344989' name="lien vers Whatsapp" className="cs-center" target="_blank">
        <Icon icon="fa6-brands:whatsapp" />
      </Link>
      <Link to='https://instagram.com/karibtech' name="lien vers instagram" className="cs-center" target="_blank">
        <Icon icon="fa6-brands:instagram" />               
      </Link>
      <Link to='https://github.com/mister97' name="lien vers GitHub" className="cs-center" target="_blank">
        <Icon icon="fa6-brands:github" />               
      </Link>
      <Link to='https://discordapp.com/users/691001689033146471' name="lien vers Discord" className="cs-center" target="_blank">
        <Icon icon="fa6-brands:discord" />               
      </Link>
    </Div>
  )
}
