import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Helmet } from 'react-helmet';

export default function ReferencementNatPage() {
  pageTitle('Référencement Naturel');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content="Boostez votre référencement naturel en Guadeloupe pour apparaître en tête des résultats. Expertise en consultation, rédaction et optimisation de contenu. Solutions personnalisées et abordables." />
      </Helmet>
      <PageHeading
        title='Référencement Naturel'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='referencement-nat'
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title="Nous sommes là pour booster votre référencement"
          subtitle='Visibilité'
          variant='cs-style1 text-center'
        />
        <Spacing lg='40' md='30' />
        <Div className="container">
          <Div className="row align-items-center">
            <Div className="col-xl-5 col-lg-6">
              <Div className="cs-radius_15 cs-shine_hover_1">
                <img src="/images/strategie-referencement-naturel-lille.png.webp" alt="Service" className='cs-radius_15 w-100' />
              </Div>
              <Spacing lg='0' md='40' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Spacing lg='50' md='30' />
              <Div className="row">
                <p><strong>Nous utilisons un éventail de techniques pour vous aider à atteindre les meilleurs classements des moteurs de recherche, ce qui permet aux internautes de trouver facilement votre site Web.</strong></p>
                <p>Nous offrons une gamme complète de services, allant de la consultation et de la stratégie à la rédaction et à l’optimisation de contenu. Nous avons l’expertise requise pour créer des solutions complètes et personnalisées qui renforceront votre présence en ligne et vous assureront d’être sur la première page des résultats de recherche.</p>
                <p>Tous nos services sont personnalisables et abordables. Quel que soit votre budget ou le temps que vous souhaitez consacrer à l’amélioration de vos efforts de référencement, nous pouvons vous aider. Nous offrons également une consultation gratuite alors appelez-nous dès aujourd’hui!</p>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">
        <h2>Qu'est-ce que le référencement naturel ? (SEO)</h2>
        <p>À ne pas confondre avec le <a href="/service/referencement-payant">référencement payant (SEA)</a>, le référencement naturel ou SEO (Search Engine Optimisation) est l’un des meilleurs moyens d’améliorer votre positionnement sur les moteurs de recherche (SERP). Il s’agit de gagner en visibilité pour bénéficier d’une augmentation de sa fréquentation et donc de votre chiffre d’affaires.</p>
        <p>
          Nous apportons donc les solutions suivantes :
          <ul>
            <li>Visibilité sur les moteurs de recherche</li>
            <li>Boost de votre visibilité en fonction des mots clés</li>
            <li>Développement de votre stratégie SEO</li>
          </ul>
        </p>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container text-center">
        <h2 className="cs-font_30 cs-m0">Budget Serré ?</h2>
        <p>Vous avez peut-être droit au <u><a href="https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-cheque-tic/categorie/administrations-et-demarches/#_" target="_blank" rel="noreferrer">chèque TIC</a></u> de la Région Guadeloupe.</p>
      </Div>
      <Spacing lg='145' md='80' />
      <Div className="container">
        <Cta
          title="Besoin de nos services ? Nous répondons à vos demandes de d'identité visuelle."
          btnText='Prendre rendez-vous'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
