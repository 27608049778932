import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import serverConfig from '../../helper/serverConfig';
import { Helmet } from 'react-helmet';

export default function ContactPage() {
  pageTitle('Contact');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    setIsSubmitting(true);

    // console.log('ok: '+JSON.stringify(data, null, 2));

    try {
      const response = await fetch(`${serverConfig.serverUrl}?action=email&token=${serverConfig.authToken}`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: { 
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(data)
      });

      // // Handle response, show success message, etc.
      console.log('Request sent successfully', response.data);
      event.target.reset();
      setSuccessMessage('Message bien reçu !');
      setErrorMessage('');
    } catch (error) {
      // Handle error, show error message, etc.
      console.error('Error sending request', error);
      setErrorMessage('Erreur, merci de réessayer ultérieurement.');
    } finally {
      setIsSubmitting(false); // Set submitting state to false after request completes
    }
  };
  
  return (
    <>
      <Helmet>
        <meta name="description" content="Contactez-nous pour toute question ou demande d'information. Nous sommes là pour vous aider à concrétiser vos projets en ligne. Êtes-vous prêt ?" />
      </Helmet>
      <PageHeading
        title="Contact"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Besoin d'informations ?"
              subtitle="Laissez-nous un message et nous nous ferons un plaisir de vous répondre dès que possible."
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={handleSubmit}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Nom complet*</label>
                <input type="text" className="cs-form_field" name="fullName" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">E-mail*</label>
                <input type="email" name="email" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Téléphone*</label>
                <input type="tel" name="tel" className="cs-form_field" required />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Sujet*</label>
                {/* <input type="text" name="subject" className="cs-form_field" /> */}
                <select className="cs-form_field" name="subject" id="subject">
                  <option value="Site Internet">Site Internet</option>
                  <option value="Web Marketing">Web Marketing</option>
                  <option value="Infographie">Infographie</option>
                  <option value="Autre">Autre</option>
                </select>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="message"
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" disabled={isSubmitting}> {/* Disable button when submitting */}
                  {isSubmitting ? (
                    <span>Envoi en cours...</span>
                  ) : (
                    <>
                      <span>Envoyer</span>
                      <Icon icon="bi:arrow-right" />
                    </>
                  )}
                </button>
              </Div>
            </form>
            <Div className="col-sm-12">
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
