import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Helmet } from 'react-helmet';

export default function ReferencementPayPage() {
  pageTitle('Référencement Payent');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <meta name="description" content="Boostez votre visibilité avec notre expertise en référencement payant en Guadeloupe. Gérez les coûts, maximisez votre présence sur les mots-clés pertinents pour votre entreprise." />
      </Helmet>
      <PageHeading
        title='Référencement Payant'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='referencement-pay'
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title="Nous vous accompagnons dans votre référencement payant"
          subtitle='Visibilité'
          variant='cs-style1 text-center'
        />
        <Spacing lg='40' md='30' />
        <Div className="container">
          <Div className="row align-items-center">
            <Div className="col-xl-5 col-lg-6">
              <Div className="cs-radius_15 cs-shine_hover_1">
                <img src="/images/internet-search-bar-website-browser-1.jpg" alt="Service" className='cs-radius_15 w-100' />
              </Div>
              <Spacing lg='0' md='40' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Spacing lg='50' md='30' />
              <Div className="row">
                <p>Le référencement payant peut vous aider à gagner de la visibilité et du trafic très rapidement sur des mots-clés pertinents.</p>
                <p>L’annonceur peut définir le budget et les limites d’enchères, ce qui permet de gérer les coûts et d’assurer un retour sur investissement.</p>
                <p>Le référencement payant (ou SEA) est une stratégie complémentaire du référencement naturel, puisqu’il vous permet de maximiser votre présence sur des mots-clés importants et pertinents pour votre entreprise.</p>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">
        <h2>Pourquoi faire appel à un expert ?</h2>
        <p>Le référencement payant, notamment avec Google Ads, peut être très complexe et nécessite des compétences stratégiques et techniques – ainsi qu’une vision globale de votre stratégie de webmarketing.</p>
        <p>Nous disposons de toutes les compétences nécessaires pour vous accompagner dans la mise en place et le suivi de la campagne SEA de votre entreprise. Nous vous guiderons à chaque étape du processus afin que nous puissions réussir ensemble !</p>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container text-center">
        <h2 className="cs-font_30 cs-m0">Budget Serré ?</h2>
        <p>Vous avez peut-être droit au <u><a href="https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-cheque-tic/categorie/administrations-et-demarches/#_" target="_blank" rel="noreferrer">chèque TIC</a></u> de la Région Guadeloupe.</p>
      </Div>
      <Spacing lg='145' md='80' />
      <Div className="container">
        <Cta
          title="Besoin de nos services ? Nous répondons à vos demandes de d'identité visuelle."
          btnText='Prendre rendez-vous'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
