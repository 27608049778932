import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import Accordion from '../Accordion'
import { Helmet } from 'react-helmet';

export default function IdentitePage() {
  pageTitle('Identité Visuelle');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <meta name="description" content="Création d'une identité visuelle unique pour votre entreprise. Logos, fournitures de bureau, brochures sur mesure. Concepts créatifs qui reflètent votre personnalité." />
      </Helmet>
      <PageHeading
        title='Identité Visuelle'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='identite'
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title="Votre projet n'a pas été conçue pour se fondre dans la masse"
          subtitle='Design & Branding'
          variant='cs-style1 text-center'
        />
        <Spacing lg='40' md='30' />
        <Div className="container">
          <Div className="row align-items-center">
            <Div className="col-xl-5 col-lg-6">
              <Div className="cs-radius_15 cs-shine_hover_1">
                <img src="/images/branding-1536x1097.jpeg" alt="Service" className='cs-radius_15 w-100' />
              </Div>
              <Spacing lg='0' md='40' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Spacing lg='50' md='30' />
              <Div className="row">
                <p>La création de votre identité de marque joue un rôle important dans la façon dont votre entreprise est perçue. Elle fera impression et représentera votre entreprise et les acteurs qui la constituent.</p>
                <p>Que vous soyez une petite entité ou une grande société, nous travaillerons avec vous afin de construire une identité forte pour votre entreprise.</p>
                <p>Vous voulez construire l’identité de votre enseigne ? Ou peut-être voulez-vous la réinventer ? Grâce à la conception de logos créatifs sur mesure, de fournitures de bureau, de brochures et de cartes de visite, nous nous efforçons d’offrir des concepts créatifs qui correspondent à la personnalité de votre entreprise.</p>
                <p>Nous sommes parfaitement conscient de ce qui fonctionne et de ce qui ne fonctionne pas, alors soyez assuré que le produit final sera harmonieux.</p>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="Q'inclut le service d'identité visuelle ?"
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container text-center">
        <h2 className="cs-font_30 cs-m0">Budget Serré ?</h2>
        <p>Vous avez peut-être droit au <u><a href="https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-cheque-tic/categorie/administrations-et-demarches/#_" target="_blank" rel="noreferrer">chèque TIC</a></u> de la Région Guadeloupe.</p>
      </Div>
      <Spacing lg='145' md='80' />
      <Div className="container">
        <Cta
          title="Besoin de nos services ? Nous répondons à vos demandes de d'identité visuelle."
          btnText='Prendre rendez-vous'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
