import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Button from '../Button'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import serverConfig from '../../helper/serverConfig'
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet';

export default function PortfolioDetailsPage() {
  const params = useParams()
  const [portfolioData, setPortfolioData] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  pageTitle('Portfolio Details');

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    try {
      const response = await fetch(`${serverConfig.serverUrl}?action=portfolio-one&token=${serverConfig.authToken}`, {
        method: 'POST',
        body: params.portfolioDetailsId,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      let jsonSoftwares = [];
      if (data) {
        jsonSoftwares = await data.software;
        // console.log(jsonSoftwares);
        jsonSoftwares = JSON.parse(jsonSoftwares);
        // console.log(jsonSoftwares);
      }
      setPortfolioData(data);
      setSoftwares(jsonSoftwares);

      // console.log('Response: ', data);
      // console.log('title: ', data.title);
    } catch (error) {
      console.error('Error sending request', error);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={`Découvrez en détail notre projet ${portfolioData.title}. Explorez notre approche, notre expertise et les résultats obtenus pour ce projet spécifique. Laissez-nous vous inspirer.`} />
      </Helmet>
      <PageHeading
        title='Portfolio Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.portfolioDetailsId}
      />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <img src={portfolioData.src ? portfolioData.src : ''} alt="Details" className="cs-radius_15 w-100" />
        <Spacing lg='90' md='40' />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title={portfolioData.title ? portfolioData.title : ''}
              subtitle='Creative'
            >
              <Spacing lg='40' md='20' />
              {/* {portfolioData.description} */}
              <div dangerouslySetInnerHTML={{ __html: portfolioData.description }} />
              <Spacing lg='40' md='20' />
              {portfolioData.href !== '' ? (
                <>
                  <a href={portfolioData.href ? portfolioData.href : 'N/A'} target="_blank" rel="noreferrer" className="cs-btn cs-style1">
                    <span>Visiter le site</span>
                    <Icon icon="bi:arrow-right" />
                  </a>
                </>
              ) : null}
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40' />
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Infos Projet -</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Catégorie :</h3>
                <p className='cs-m0'>{portfolioData.category ? portfolioData.category.toUpperCase() : 'N/A'}</p>
                <Spacing lg='30' md='30' />
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client :</h3>
                <p className='cs-m0'>{portfolioData.client ? portfolioData.client : ''}</p>
                <Spacing lg='30' md='30' />
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Outils utilisés :</h3>
                <p className='cs-m0'>
                  {softwares.map((software, index) => (
                    <li key={index}>{software}</li>
                  ))}
                </p>
                <Spacing lg='30' md='30' />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10' />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button btnLink='/portfolio' btnText='Retour au portfolio' variant='cs-type1' />
          </Div>
          {/* <Div>
                  <Button btnLink='/portfolio/portfolio-details' btnText='Next Project'/>
                </Div> */}
        </Div>
      </Div>
      <Spacing lg='145' md='80' />
      <Cta
        title='<a href="mailto:info@karib-tech.com">info@karib-tech.com</a>'
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      />
    </>
  )
}
