import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Helmet } from 'react-helmet';

export default function ConceptionPage() {
  pageTitle('Conception de site Web');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
	<Helmet>
	  <meta name="description" content="Conception de site web sur mesure. Expérience utilisateur exceptionnelle. Votre site web, votre 24/7. Expertise WordPress. Augmentez vos contacts efficacement." />
	</Helmet>
      <PageHeading 
        title='Conception de site Web'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='conception'
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Votre site web sur mesure' 
          subtitle='Développement et Design' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='40' md='30'/>
				<Div className="container">
					<Div className="row align-items-center">
						<Div className="col-xl-5 col-lg-6">
							<Div className="cs-radius_15 cs-shine_hover_1">
								<img src="/images/conception-web-1536x864.jpeg" alt="Service" className='cs-radius_15 w-100' />
							</Div>
							<Spacing lg='0' md='40'/>
						</Div>
						<Div className="col-lg-6 offset-xl-1">
							{/* <h2 className="cs-font_50 cs-m0">Below our most design related services</h2> */}
							<Spacing lg='50' md='30'/>
							<Div className="row">
									<p>Chaque site web est unique et construit à partir de zéro pour chaque client. Cela signifie qu’ils sont construits selon vos besoins. Lorsque nous concevons votre site Web, nous plaçons le visiteur au premier plan afin de produire une expérience utilisateur (UX) exceptionnelle.</p>
									<p>Nous fournissons plus qu’un simple site internet. Votre site web est votre employé en ligne 24/7, celui-ci augmente vos contacts de manière significative.</p>
									<p>Pour rendre le processus de conception et de développement plus facile et plus rapide, nous construisons votre site web sur WordPress qui est le CMS (Content Management System) le plus populaire. WordPress compte actuellement plus de 28 millions de sites Web actifs dans le monde, ce qui représente environ 45 % de la part de marché des CMS.</p>
							</Div>
						</Div>
					</Div>
				</Div>
      </Div>
      <Spacing lg='120' md='50'/>
			<Div className="container">
				<h2 className="cs-font_50 cs-m0">Nous travaillons selon un processus en 6 étapes</h2>
				<Spacing lg='40' md='30'/>
				<p>Un bon design n’est pas seulement une question de technique. En fait, il s’agit surtout d’avoir un processus de création et une stratégie clairs. C’est pourquoi nous suivons un processus en 6 étapes, nous prenons en compte tous les facteurs et les applique à votre site Web.</p>
				<Spacing lg='60' md='30'/>
				<Div className="row">
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_1.svg'
									title='Planification initiale'
									subtitle='Nous effectuons nos recherches et détermine les objectifs de votre site Web.'
							/>
							<Spacing lg='30' md='30'/>
						</Div>
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_2.svg'
									title='Création de la maquette'
									subtitle='Nous planifions la structure et le fonctionnement de votre futur site internet.'
							/>
							<Spacing lg='30' md='30'/>
						</Div>
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_3.svg'
									title='Design'
									subtitle="Nous dessinons votre site web sur Photoshop. C'est la première occasion pour vous de voir à quoi il ressemblera."
							/>
							<Spacing lg='30' md='30'/>
						</Div>
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_1.svg'
									title='Développement'
									subtitle='Nous commençons à développer le site web sur notre serveur de test en utilisant les designs finaux.'
							/>
							<Spacing lg='30' md='30'/>
						</Div>
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_2.svg'
									title='Contenu'
									subtitle='Une fois les fonctionnalités du site web développées, nous insérons le contenu.'
							/>
							<Spacing lg='30' md='30'/>
						</Div>
						<Div className="col-lg-4">
							<IconBox
									icon='/images/icons/service_icon_3.svg'
									title='Tests'
									subtitle='Le site Web est soumis à un contrôle qualité complet pour garantir des performances et une fiabilité optimales.'
							/>
							{/* <Spacing lg='30' md='30'/> */}
						</Div>
				</Div>
			</Div>
      <Spacing lg='120' md='50'/>
			<Div className="container text-center">
				<h2 className="cs-font_30 cs-m0">Budget Serré ?</h2>
				<p>Vous avez peut-être droit au <u><a href="https://www.regionguadeloupe.fr/les-aides-les-services/guide-des-aides/detail/actualites/aide-cheque-tic/categorie/administrations-et-demarches/#_" target="_blank" rel="noreferrer">chèque TIC</a></u> de la Région Guadeloupe.</p>
			</Div>
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <Cta 
          title='Besoin de nos services ? Nous répondons à vos demandes de création de site Web.' 
          btnText='Prendre rendez-vous' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
