import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';
const timelineData = [
  [
    {
      year: '',
      name: 'Site WordPress',
      position: 'L\'interface d\'administration (Back office) WordPress, vous permet de mettre à jour le contenu de votre site en tout simplicité et autonomie.',
      type: '',
    },
    {
      year: '',
      name: 'Site Ecommerce',
      position: 'Vous souhaitez vendre en ligne ? Qu\'il s\'agisse d\'un service ou d\'une gamme de produits, nous vous proposons la création de votre boutique.',
      type: '',
    },
  ],
  [
    {
      year: '',
      name: 'Développement spécifique',
      position: 'Le développement "Spécifique" d\'un site ou d\'une application permet de répondre à des besoins totalement... "Spécifiques"',
      type: '',
    },
    {
      year: '',
      name: 'Infographie',
      position: 'Un visuel vaut mieux qu\'un long discours. L\'infographie permet de synthétiser de manière claire et percutante des informations complexes. ',
      type: '',
    },
  ],
];

export default function TimelineSlider() {
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <Timeline columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
