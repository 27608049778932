import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'Ressources pour les réseaux sociaux',
    answer:
      'Nous vous fournissons tous les éléments de conception dont vous avez besoin pour lancer vos comptes de réseaux sociaux.',
  },
  {
    question: 'Marque et indentité',
    answer:
      'Les directives sur l\'image de marque seront votre manuel pour maintenir la continuité de celle-ci. (Couleurs, polices, variations du logo).',
  },
  {
    question: 'Conception de logo',
    answer:
      'Votre logo est la partie essentielle de votre identité, il donne le ton.',
  },
  {
    question: 'Fournitures de bureau',
    answer:
      'Vous pouvez compléter votre marque avec des fournitures de bureau et d\'autres matériels promotionnels.',
  },
  {
    question: 'Conception de flyers',
    answer:
      'Un dépliant peut être un moyen efficace de promouvoir vos produits/services.',
  },
  {
    question: 'Graphisme de marketing par courriel',
    answer:
      'La publicité par courrier électronique est un moyen peu coûteux de communiquer avec les clients. Et un bon design de ces emails attire leur attention.',
  },
];

export default function Accordion() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
