import React, { useState, useEffect } from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import './footer.scss';

const copyrightLinks = [
  { title: 'CGU', href: '/conditions-generales-dutilisation' },
  { title: 'CGV', href: '/conditions-generales-de-vente' },
];

const serviceMenu = [
  { title: 'Conception site Web', href: '/service/conception-site-web' },
  { title: 'Maintenance site Web', href: '/service/maintenance-site-web' },
  { title: 'Identité Visuelle', href: '/service/identite-visuelle' },
  { title: 'Référencement Naturel', href: '/service/referencement-naturel' },
  { title: 'Référencement Payant', href: '/service/referencement-payant' },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/kt-logo-white.png"
                  logoAlt="Logo"
                  text="Votre expert en conception de sites web en Guadeloupe, vous propose des solutions créatives personnalisées et adaptées aux besoins de votre entreprise."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget withIcon title="Contact" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © {currentYear} | Tous Droits réservés</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
