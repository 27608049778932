import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import PricingTableList from '../PricingTable/PricingTableList'
import { Helmet } from 'react-helmet';

export default function MaintenancePage() {
  pageTitle('Maintenance site Web');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
      <meta name="description" content="Assistance rapide et fiable pour la maintenance de votre site WordPress. Résolution de problèmes, mises à jour, sauvegarde des données. Contactez-nous au 0690 344 989." />
    </Helmet>
      <PageHeading 
        title='Maintenance site Web'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='maintenance'
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Une assistance rapide et fiable' 
          subtitle='Support & Sécurité' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='40' md='30'/>
				<Div className="container">
					<Div className="row align-items-center">
						<Div className="col-xl-5 col-lg-6">
							<Div className="cs-radius_15 cs-shine_hover_1">
								<img src="/images/maintenance-web-1536x1024.jpeg" alt="Service" className='cs-radius_15 w-100' />
							</Div>
							<Spacing lg='0' md='40'/>
						</Div>
						<Div className="col-lg-6 offset-xl-1">
							<Spacing lg='50' md='30'/>
							<Div className="row">
									<p>Nous vous proposons un support et une maintenance rapides et fiables de votre site WordPress.</p>
									<p>Nous pouvons vous aider à résoudre les problèmes liés à votre site, à mettre à jour le contenu et le design, à héberger votre site web, à sauvegarder vos données et plus encore. Nous supervisons votre site web et sommes alertés en cas de problème afin de le résoudre rapidement.</p>
                                    <p>Si vous rencontrez un problème avec votre site web, veuillez appeler le&nbsp;<b><a href="tel:+590690344989">0690 344 989</a></b>&nbsp;ou envoyer un courriel à&nbsp;<b><a href="mailto:info@karib-tech.com">info@karib-tech.com</a></b>.</p>
							</Div>
						</Div>
					</Div>
				</Div>
      </Div>
      <Spacing lg='120' md='50'/>
            <Div className="container text-center">
                <h2 className="cs-font_50 cs-m0">Les forfaits mensuels en bref</h2>
                <Spacing lg='40' md='30'/>
                <p>Nous offrons des formules mensuelles qui comprennent tous les services dont vous avez besoin pour assurer le bon fonctionnement de votre site Web. En outre, nous proposons une maintenance d’urgence pour les clients qui ont besoin d’aide immédiatement.</p>
                <p>Si vous êtes intéressé par l’un de ces packs, vous pouvez prendre contact avec nous afin de discuter de vos besoins.</p>
                <Spacing lg='60' md='30'/>
                <PricingTableList/>
            </Div>
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <Cta 
          title='Besoin de nos services ? Nous répondons à vos demandes de Maintenance.' 
          btnText='Prendre rendez-vous' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
