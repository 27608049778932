import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a href="tel:+590690344989">0690 344 989</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:whatsapp" /></span>:''}
          <a href="https://wa.me/590690344989" target="_blank" rel="noreferrer">+590 690 344 989</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href="mailto:info@karib-tech.com">info@karib-tech.com</a>
        </li>
      </ul>
    </>
  )
}
