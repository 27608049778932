import React from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
  return (
    <Section className="position-relative">
      <Section className="row">
        <Section className="col-lg-4">
          <PricingTable 
            title='Basic'
            price='29.99'
            currency='€'
            timeline='mensuel'
            features={['1h d\'assistance par mois', '1 mise à jour par mois', 'Modification du contenu existant', 'Analyse des logiciels malveillants', 'Pare-feu et sécurité avancés']}
            // btnText=''
            // btnLink='/'
          />
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
          <PricingTable 
            title='Plus'
            price='54.99'
            currency='€'
            timeline='mensuel'
            features={['2h d\'assistance par mois',  '2 mise à jour par mois', 'Modification du contenu existant', 'Analyse des logiciels malveillants', 'Pare-feu et sécurité avancés', 'Ajout de nouveau contenu']}
            // btnText=''
            // btnLink='/'
          />
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
          <PricingTable 
            title='Premium'
            price='79.99'
            currency='€'
            timeline='mensuel'
            features={['3h d\'assistance par mois',  '1 mise à jour par semaine', 'Modification du contenu existant', 'Analyse des logiciels malveillants', 'Pare-feu et sécurité avancés', 'Ajout de nouveau contenu', 'Nettoyage des spams']}
            // btnText=''
            // btnLink='/'
          />
          <Spacing lg='25' md='25'/>
        </Section>
      </Section>
    </Section>
  )
}
