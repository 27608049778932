import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import serverConfig from '../../helper/serverConfig';
import { Helmet } from 'react-helmet';

const categoryMenu = [
  {
    title: 'Web',
    category: 'web',
  },
  {
    title: 'Infographie',
    category: 'infographie',
  },
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const [portfolioData, setPortfolioData] = useState([]);
  
  const fetchData = async () => {
    try {
      const response = await fetch(`${serverConfig.serverUrl}?action=portfolio&token=${serverConfig.authToken}`, {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      // console.log(await response.json());

      const data = await response.json();
      const dataArray = Object.values(data);
      setPortfolioData(dataArray);

      // console.log('Response: ', dataArray);
    } catch (error) {
      console.error('Error sending request', error);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Découvrez notre portfolio diversifié et créatif en Guadeloupe. Laissez nos réalisations parler pour notre expertise en conception de sites web, identités visuelles et plus encore." />
      </Helmet>
      <PageHeading
        title="Portfolio"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Nos dernières créations" subtitle="Notre Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>Tout</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                id={item.id}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Afficher Plus</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@karib-tech.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
